import React from "react"
import Posts from "./posts"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"

// export const pageQuery = graphql`
//     query GhostPostQuery($limit: Int!, $skip: Int!) {
//         allGhostPost(
//             sort: { order: DESC, fields: [published_at] }
//             limit: $limit
//             skip: $skip
//         ) {
//             edges {
//                 node {
//                     ...GhostPostFields
//                 }
//             }
//         }
//     }
// `

// markup
const BlogPage = () => {
    // const data = useStaticQuery(pageQuery)
    // const posts = data.allGhostPost.edges

    return <Layout title="Blog">{/* <Posts /> */}</Layout>
}

export default BlogPage
